import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import Navigation from "./navigation";

import "../styles/typography.css";
import {
  faGithub,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;

  }

  html {
    font-size: 62.5%
  }

  body {
    min-height: 100vh;
    font-family: "Lexend", Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    width: 100%;
    max-height: 100vh;
    background: #0006F3;
    color: #fff;
  }
`;

const SocialMedia = styled.div`
  color: white;
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  max-width: 17rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: 490px) {
    display: flex;
    max-width: 4rem;
    flex-direction: column;
    justify-content: space-evenly;
    right: 1rem;
  }
`;

const theme = {
  // background: "#FFEFAE",
};

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <GlobalStyle theme={theme} />
      <Navigation />
      {children}
      <SocialMedia>
        <a href="https://twitter.com/tochistry">
          <FontAwesomeIcon icon={faTwitter} size="2x" color="#fff" />
        </a>
        <a href="https://www.linkedin.com/in/tochukwu-nwanguma/">
          <FontAwesomeIcon icon={faLinkedin} size="2x" color="#fff" />
        </a>
        <a href="https://github.com/nwanguma">
          <FontAwesomeIcon icon={faGithub} size="2x" color="#fff" />
        </a>
        <a href="mailto:nwangumat@gmail.com">
          <FontAwesomeIcon icon={faEnvelope} size="2x" color="#fff" />
        </a>
      </SocialMedia>
    </React.Fragment>
  );
};

export default Layout;
