import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.nav`
  position: fixed;
  top: 2rem;
  left: 4rem;
  height: max-content;
  padding: 1rem;
  width: max-content;
  color: #fff;
  font-size: 1.7rem;

  @media screen and (max-width: 900px) {
    width: 100%;
    left: 0;
    top: 0;
    background: #0008f7;
    opacity: 1;
    z-index: 3;
  }
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media screen and (max-width: 900px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const NavListItem = styled.li`
  &:not(:first-child) {
    margin-top: 2rem;
  }

  @media screen and (max-width: 900px) {
    flex: 0 0 30%;

    &:not(:first-child) {
      margin-top: 0;
    }
  }
`;

const NavListItemExternal = styled.a`
  text-decoration: underline;
  color: inherit;
  display: inline-block;
  margin-right: 0.5rem;
`;

const NavListItemLink = styled(Link)`
  text-decoration: underline;
  color: inherit;
  display: inline-block;
  margin-right: 0.5rem;
`;

const Navigation = () => {
  return (
    <Wrapper>
      <NavList>
        <NavListItem>
          <NavListItemLink to="/">Home</NavListItemLink>
        </NavListItem>
        <NavListItem>
          <NavListItemLink to="/about">About</NavListItemLink>
        </NavListItem>
        <NavListItem>
          <NavListItemLink to="/projects">Projects</NavListItemLink>
        </NavListItem>
        <NavListItem>
          <NavListItemExternal
            href="https://nwanguma.medium.com"
            target="_blank"
          >
            Blog
          </NavListItemExternal>
          <FontAwesomeIcon icon={faExternalLinkAlt} color="#fff" />
        </NavListItem>
        <NavListItem>
          <NavListItemExternal
            href="https://form.typeform.com/c/iJ7GkqD1"
            target="_blank"
          >
            Contact
          </NavListItemExternal>
          <FontAwesomeIcon icon={faExternalLinkAlt} color="#fff" />
        </NavListItem>
        <NavListItem>
          <NavListItemExternal
            href="https://docs.google.com/document/d/1QFfu_Rm6lihsX4gv5jnHnDwHTp2WkS7Kf2l4dtH_jq8/edit"
            target="_blank"
          >
            Resume
          </NavListItemExternal>
          <FontAwesomeIcon icon={faExternalLinkAlt} color="#fff" />
        </NavListItem>
      </NavList>
    </Wrapper>
  );
};

export default Navigation;
